import React, { useEffect, useState } from 'react';

import {
  Box,
  Circle,
  Divider,
  Heading,
  Icon,
  ListItem,
  Tabs,
  TabList,
  TabPanels,
  Tab,
  TabPanel,
  Text,
  UnorderedList,
} from '@chakra-ui/react';

import { ChakraBox, SEO } from '../components';
import { Background, Product } from '../lib/types';
import { useAppDispatch } from '../redux/hooks';
import { changeBackground, changeCurrentLogo } from '../redux/reducers/ui';
import { PageLayout, Section } from '../widgets';

import '../assets/fonts/jakarta.css';

const variants = {
  bridge: { y: 0 },
  connect: { y: 65 },
};

const TermsPage = () => {
  const [target, setTarget] = useState('bridge');

  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(changeBackground(Background.Secondary));
    dispatch(changeCurrentLogo({ product: Product.Arteria }));
  }, [dispatch]);

  const planetWithRing = (
    <svg
      width="140"
      height="149"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M69.978 109.019c21.566 0 39.05-17.484 39.05-39.05 0-21.567-17.484-39.05-39.05-39.05-21.567 0-39.05 17.483-39.05 39.05 0 21.566 17.483 39.05 39.05 39.05Z"
        fill="#26A9E0"
      />
      <path
        d="M128.382 128.382c6.389-6.389-14.581-37.718-46.839-69.975C49.286 26.15 17.957 5.179 11.568 11.568 5.18 17.958 26.15 49.286 58.407 81.543c32.257 32.258 63.586 53.228 69.975 46.839Z"
        stroke="#26A9E0"
        strokeWidth=".8"
        stroke-miterlimit="10"
      />
      <path
        d="M89.907 17.238a4.47 4.47 0 1 0 0-8.94 4.47 4.47 0 0 0 0 8.94ZM75.788 148.059a9.32 9.32 0 1 0 0-18.64 9.32 9.32 0 0 0 0 18.64Z"
        fill="#26A9E0"
      />
    </svg>
  );

  const planet = (
    <svg width="55" height="55" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M27.04 54.08c14.934 0 27.04-12.106 27.04-27.04S41.974 0 27.04 0 0 12.106 0 27.04s12.106 27.04 27.04 27.04Z"
        fill="#26A9E0"
      />
    </svg>
  );

  const star = (
    <svg width="56" height="83" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M27.76.88C24.2 29.92 25.25 38.47.41 41.7c24.42 3.96 24.77 12.91 27.35 40.82 3.4-28.08 2.74-36.49 27.35-40.82C30.3 38.51 31.13 29.88 27.76.88Z"
        fill="#2A55A2"
      />
    </svg>
  );

  const imagesList = [
    {
      svg: planet,
      viewBox: '0 0 55 55',
      width: '40.98px',
      bottom: ['-9%', '20%'],
      right: ['85%', '90%'],
      hasMotion: false,
    },
    {
      svg: planet,
      viewBox: '0 0 55 55',
      width: '7.82px',
      bottom: ['-4%', '15.5%'],
      right: ['80%', '91%'],
      hasMotion: false,
    },
    {
      svg: planet,
      viewBox: '0 0 55 55',
      width: '19.62px',
      bottom: '70%',
      right: '7%',
      hasMotion: true,
      display: ['none', 'block'],
      animate: { x: -115, y: 50 },
    },
    {
      svg: planet,
      viewBox: '0 0 55 55',
      width: '54.08px',
      bottom: '70%',
      right: '15%',
      hasMotion: true,
      display: ['none', 'block'],
      animate: { x: 110, y: 35 },
    },
    {
      svg: star,
      viewBox: '0 0 56 83',
      width: '71.8px',
      bottom: '0%',
      right: '95%',
      hasMotion: true,
      display: ['none', 'block'],
      animate: { x: -60, y: 170 },
    },
    {
      svg: star,
      viewBox: '0 0 56 83',
      width: ['21.38px', '26.78px'],
      bottom: ['90%', '80%'],
      right: ['18%', '110%'],
      hasMotion: [true, false],
      animate: { x: -120, y: -50 },
    },
    {
      svg: star,
      viewBox: '0 0 56 83',
      width: '17.32px',
      bottom: '40%',
      right: ['117%', '97%'],
      hasMotion: false,
    },
    {
      svg: star,
      viewBox: '0 0 56 83',
      width: '44.22px',
      bottom: '46%',
      right: '107%',
      hasMotion: true,
      display: ['none', 'block'],
      animate: { x: 100, y: -17 },
    },
    {
      svg: star,
      viewBox: '0 0 56 83',
      width: '50.20px',
      bottom: '32%',
      right: '87%',
      hasMotion: true,
      animate: { x: -210, y: 37 },
    },
    {
      svg: star,
      viewBox: '0 0 56 83',
      width: '26.78px',
      bottom: '52%',
      right: '74%',
      hasMotion: false,
      display: ['none', 'block'],
    },
    {
      svg: star,
      viewBox: '0 0 56 83',
      width: '54.7px',
      bottom: '5%',
      right: '50%',
      hasMotion: false,
    },
    {
      svg: star,
      viewBox: '0 0 56 83',
      width: '26.78px',
      bottom: ['50%', '42%'],
      right: ['13%', '43%'],
      hasMotion: false,
    },
    {
      svg: star,
      viewBox: '0 0 56 83',
      width: ['29px', '21.6px'],
      bottom: ['-11%', '-3%'],
      right: ['44%', '30%'],
      hasMotion: false,
    },
    {
      svg: star,
      viewBox: '0 0 56 83',
      width: '50.02px',
      bottom: '-19%',
      right: '90%',
      hasMotion: true,
      display: ['none', 'block'],
      animate: { x: 420, y: 18 },
    },
    {
      svg: star,
      viewBox: '0 0 56 83',
      width: '32.6px',
      bottom: '70%',
      right: '40%',
      hasMotion: true,
      animate: { x: -310, y: -18 },
    },
    {
      svg: star,
      viewBox: '0 0 56 83',
      width: ['23.56px', '53.56px'],
      bottom: ['89%', '87%'],
      right: ['70%', '30%'],
      hasMotion: true,
      animate: { x: -3, y: 165 },
    },
    {
      svg: star,
      viewBox: '0 0 56 83',
      width: '21.6px',
      bottom: '67%',
      right: '30%',
      hasMotion: true,
      display: ['none', 'block'],
      animate: { x: 180, y: 130 },
    },
    {
      svg: star,
      viewBox: '0 0 56 83',
      width: '21.6px',
      bottom: '35%',
      right: '40%',
      hasMotion: true,
      display: ['none', 'block'],
      animate: { x: 210, y: -15 },
    },
    {
      svg: star,
      viewBox: '0 0 56 83',
      width: '54.7px',
      bottom: '35%',
      right: '5%',
      hasMotion: true,
      display: ['none', 'block'],
      animate: { x: -115, y: -35 },
    },
    {
      svg: star,
      viewBox: '0 0 56 83',
      width: '21.6px',
      bottom: '0%',
      right: '10%',
      hasMotion: true,
      display: ['none', 'block'],
      animate: { x: -55, y: 155 },
    },
  ];

  return (
    <>
      <SEO
        title="Terms & conditions"
        description="Emerging markets are complicated, we're not"
        image="../assets/images/logos/arteria.svg"
      />
      <PageLayout>
        <Section>
          <Box
            color="white"
            position="relative"
            mt={['-25%', '-5%']}
            ml={['25%', '17%']}
          >
            <Icon
              w={['45%', '20%']}
              h="20%"
              viewBox="0 0 140 149"
              position="absolute"
              bottom={['71%', '0%']}
              right={['10%', '5%']}
            >
              {planetWithRing}
            </Icon>
            {imagesList.map((item, index) => (
              <ChakraBox
                key={index}
                animate={item.hasMotion ? item.animate : { x: 0, y: 0 }}
                display={item.display}
                transition={{ duration: '2.5' }}
                bottom={item.bottom}
                right={item.right}
                pos="absolute"
              >
                <Icon key={index} w={item.width} h="20%" viewBox={item.viewBox}>
                  {item.svg}
                </Icon>
              </ChakraBox>
            ))}
            <Tabs
              orientation="vertical"
              variant="unstyled"
              w="100%"
              pt={100}
              ml={['-20%', 0]}
              flexDir={['column', 'row']}
            >
              <TabList mr={[0, 10]} ml={0} mb={[10, 0]} zIndex={1}>
                <Tab
                  my={3}
                  fontWeight="bold"
                  color={target == 'bridge' ? 'brandSecondary.400' : 'white'}
                  _focus={{ borderColor: 'transparent' }}
                  alignSelf={['start', 'center']}
                  onClick={() => setTarget('bridge')}
                >
                  bridge
                </Tab>
                <Tab
                  my={3}
                  fontWeight="bold"
                  color={target == 'connect' ? 'brandSecondary.400' : 'white'}
                  _focus={{ borderColor: 'transparent' }}
                  alignSelf={['start', 'center']}
                  onClick={() => setTarget('connect')}
                >
                  connect
                </Tab>
              </TabList>
              <Divider
                orientation="vertical"
                h="70vh"
                display={['none', 'block']}
              />
              <Box position="relative" display={['none', 'block']}>
                <ChakraBox animate={target} variants={variants}>
                  <Circle
                    size="14px"
                    bg="brandSecondary.400"
                    position="absolute"
                    left="-7px"
                    top="25px"
                  />
                </ChakraBox>
              </Box>
              <TabPanels zIndex="1">
                <TabPanel
                  w={['120%', '31vh', '40vh', '50vh', '55vh', '70vh']}
                  overflowY="scroll"
                  maxHeight={['50vh', '55vh', '55vh', '60vh', '60vh', '70vh']}
                  ml={[0, 10]}
                >
                  <Heading as="h2" size="2xl">
                    Terms & Conditions
                  </Heading>
                  <Text
                    mt={8}
                    fontSize="0.938rem"
                    letterSpacing="0.1em"
                    lineHeight="1.25rem"
                  >
                    Your customers will need to sign, the following terms and
                    conditions for the Arteria Bridge services.
                    <UnorderedList mt={10}>
                      <ListItem
                        color={'brandSecondary.400'}
                        onClick={() =>
                          window.open(
                            'https://www.bridge.arteria.xyz/terms',
                            '_blank',
                          )
                        }
                        fontWeight={800}
                        cursor="pointer"
                        textDecor="underline"
                      >
                        Terms and Conditions.
                      </ListItem>
                    </UnorderedList>
                  </Text>
                </TabPanel>
                <TabPanel
                  w={['120%', '31vh', '40vh', '50vh', '55vh', '70vh']}
                  overflowY="scroll"
                  maxHeight={['50vh', '55vh', '55vh', '60vh', '60vh', '70vh']}
                  ml={[0, 10]}
                >
                  <Heading as="h2" size="2xl">
                    Terms & Conditions
                  </Heading>
                  <Text
                    mt={8}
                    fontSize="0.938rem"
                    letterSpacing="0.1em"
                    lineHeight="1.25rem"
                  >
                    In order to use the Arteria Connect services in connection
                    with a Mexican pesos account, Users will need to sign the
                    following legal documents of Cuenca Tecnología Financiera,
                    S.A. de C.V. Institución de Fondos de Pago Electrónico:
                    <UnorderedList mt={10}>
                      <ListItem
                        color={'brandSecondary.400'}
                        onClick={() =>
                          window.open(
                            'https://cuenca.com/contrato-adhesion/',
                            '_blank',
                          )
                        }
                        fontWeight={800}
                        cursor="pointer"
                        textDecor="underline"
                      >
                        Standard Format Agreement of deposit of electronic
                        payment funds.
                      </ListItem>
                      <ListItem
                        color={'brandSecondary.400'}
                        onClick={() =>
                          window.open(
                            'https://cuenca.com/aviso-privacidad/',
                            '_blank',
                          )
                        }
                        fontWeight={800}
                        cursor="pointer"
                        textDecor="underline"
                      >
                        Data Policy.
                      </ListItem>
                      <ListItem
                        color={'brandSecondary.400'}
                        onClick={() =>
                          window.open(
                            'https://cuenca.com/terminos-y-condiciones/',
                            '_blank',
                          )
                        }
                        fontWeight={800}
                        cursor="pointer"
                        textDecor="underline"
                      >
                        Terms and Conditions.
                      </ListItem>
                    </UnorderedList>
                  </Text>
                </TabPanel>
              </TabPanels>
            </Tabs>
          </Box>
        </Section>
      </PageLayout>
    </>
  );
};

export default TermsPage;
